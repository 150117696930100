<template>
  <b-card
    no-body
    class="p-2"
  >
    <h3 class="mb-2">
      {{ $t('humanResources.workArea.edit.editWorkArea') }}
    </h3>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- name work area field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Customs"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workArea.columns.workArea')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="WorkArea.nombre"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Description Field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workArea.columns.description')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="WorkArea.descripcion"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Departament field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="departament"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workArea.columns.departament')"
              >
                <v-select
                  v-model="WorkArea.departamentoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :reduce="option => option.id"
                  :options="departamentsOptions"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- employee field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="empleado-jefe"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workArea.columns.areaManager')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="WorkArea.empleadoJefeId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :reduce="option => option.id"
                  :options="employees"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          {{ $t('Lists.saveChanges') }}
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  // BFormTextarea,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import router from '@/router'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,

  },
  setup() {
    /* SERVICES */
    const {
      updateWorkArea,
      fetchWorkArea,
      fetchDepartamentsOptions,
      fetchEmployesOptions,
    } = humanResources()

    // Data
    const WorkArea = ref({})
    fetchWorkArea(router.currentRoute.params, data => {
      WorkArea.value = data
    })
    const departamentsOptions = ref([])
    const employees = ref([])
    /* Methods */
    fetchDepartamentsOptions(data => {
      departamentsOptions.value = data
    })
    fetchEmployesOptions(data => {
      employees.value = data
    })
    /* Events */
    const onSubmit = () => {
      updateWorkArea(WorkArea.value)
        .then(() => {
          router.push({ name: 'apps-human-resources-work-area-list' })
        })
    }
    /* Validations */
    const workAreaData = ref(JSON.parse(JSON.stringify(WorkArea.value)))
    const resetclienteData = () => {
      workAreaData.value = JSON.parse(JSON.stringify(WorkArea))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetclienteData)
    return {
      // Data
      WorkArea,
      departamentsOptions,
      employees,
      /* Events */
      onSubmit,
      // Validations
      required,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
